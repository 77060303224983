import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Start from '../scene/Circle'
import style from './startmodal.module.css'

const strokeStyle = {
  stroke: 'rgba(63, 136, 191, 40%)',
  strokeWidth: '4px',
  fill: 'none',
}

const StartModal = ({
  show,
  onShutDown,
  text,
  startProps,
}) => {

  // Need to get the size of svg and Circle radius.
  const { radius } = startProps
  // Stroke width is split 50-50 inside and outside the circle.
  // Get stroke width from the style object.
  const re = /\d+/
  const strokeWidth = parseFloat( re.exec( strokeStyle.strokeWidth )[0])

  /*
   * Handlers
   */
   // Modal's shut down callback.
  const shutDown = (e) => {
    onShutDown()
  }

  return (
    <Modal
      show={show}
      centered={true}
      size='lg'
      animation={true}
      backdrop={'static'}
    >
      <Modal.Body >
        <div className={style.body} >

          <div className={style.text} >
            <h4> {text && text.title } </h4>
            <hr/>
            <div dangerouslySetInnerHTML = {text && {__html: text.body }} />
          </div>
          {/*
            The svg divs needs to follow the text div,
            so it is on top and pointerUp will fire on it.
          */}
          <div
            className={style.svg}
            onPointerUp={shutDown}
            >
            <svg
              width={ 2 * radius + strokeWidth }
              height={ 2 * radius + strokeWidth }
            >
              <Start
                x={ radius + strokeWidth/2 }
                y={ radius + strokeWidth/2 }
                radius={ radius }
                style={strokeStyle}
              />
            </svg>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  )
}
export default StartModal
