// All the Slice Reducers
import sceneReducer from '../features/scene/sceneSlice'
import binReducer from '../features/bin/binSlice'
import phaseReducer from '../features/phase/phaseSlice'
import experimentReducer from '../features/experiment/experimentSlice'

// Cross Slice Case Reducers
import {
  readTrialHistoryReducer,
  setTargetAngleReducer,
} from './bin/binCrossSlice'
import {
  setBinParamsReducer,
  setRotationReducer,
  setBinTargetAnglesReducer,
  readBinHistoryReducer,
} from './phase/phaseCrossSlice'
import {
  setPhaseParamsReducer,
  setSceneParamsReducer,
  readPhaseHistoryReducer,
} from './experiment/experimentCrossSlice'


/*
 * Action Types
 */
 // Bin's cross actions with Scene
export const READ_TRIAL_HISTORY = 'READ_TRIAL_HISTORY'
export const SET_TARGET_ANGLE = 'SET_TARGET_ANGLE'
// Phase's cross actions with Bin
export const SET_BIN_PARAMS = 'SET_BIN_PARAMS'
export const SET_TARGET_ANGLES = 'SET_TARGET_ANGLES'
export const READ_BIN_HISTORY = 'READ_BIN_HISTORY'
// Phase's cross actions with Scene
export const SET_ROTATION = 'SET_ROTATION'
// Exeriment's cross action with Phase
export const SET_PHASE_PARAMS = 'SET_PHASE_PARAMS'
export const READ_PHASE_HISTORY = 'READ_PHASE_HISTORY'
export const SET_SCENE_PARAMS = 'SET_SCENE_PARAMS'

/*
 *  The reducer that manages actions that require states from other slices.
 */
const crossSliceReducer = (state, action) => {
  // console.log('In crossSliceReducer')
  // console.log(action.type)
  switch (action.type) {
    // Bin cross slice actions with Scene
    case READ_TRIAL_HISTORY:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: readTrialHistoryReducer( state.bin, action, state.scene ),
        scene: sceneReducer( state.scene, action ),
      }
    case SET_TARGET_ANGLE:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: binReducer( state.bin, action ),
        scene: setTargetAngleReducer( state.bin, action, state.scene ),
      }
    // Phase cross slice actions with Bin
    case SET_BIN_PARAMS:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: setBinParamsReducer( state.phase, action, state.bin ),
        scene: sceneReducer( state.scene, action ),
      }
    // Phase cross slice actions with Bin
    case SET_ROTATION:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: binReducer( state.bin, action),
        scene: setRotationReducer( state.phase, action, state.scene ),
      }
    case SET_TARGET_ANGLES:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: setBinTargetAnglesReducer( state.phase, action, state.bin ),
        scene: sceneReducer( state.scene, action ),
      }
    case READ_BIN_HISTORY:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: readBinHistoryReducer( state.phase, action, state.bin ),
        bin: binReducer( state.bin, action ),
        scene: sceneReducer( state.scene, action ),
      }
    case SET_PHASE_PARAMS:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: setPhaseParamsReducer(state.experiment, action, state.phase),
        bin: binReducer( state.bin, action),
        scene: sceneReducer( state.scene, action ),
      }
    case SET_SCENE_PARAMS:
      return {
        experiment: experimentReducer(state.experiment, action),
        phase: phaseReducer( state.phase, action ),
        bin: binReducer( state.bin, action ),
        scene: setSceneParamsReducer(state.experiment, action, state.scene)
      }
    case READ_PHASE_HISTORY:
      return {
        experiment: readPhaseHistoryReducer(state.experiment, action, state.phase),
        phase: phaseReducer(state.phase, action),
        bin: binReducer( state.bin, action),
        scene: sceneReducer( state.scene, action ),
      }
    default:
      return state
  } // end switch
} // end function crossSliceReducer
export default crossSliceReducer
