import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  targetAngles: [],
  rotation: 0,
  binCount: 1,
  binNum: 1,
  history: {},
  showIntro: false, // this is important.
  // From Experiment
  phaseName: undefined,
  introModal: {},
}

export const phaseSlice = createSlice({
  name: 'phase',
  initialState: initialState,
  reducers: {
    setPhaseParams: ( state, action ) => { // I don't think that I need.
      // I think that I will use this to set Intro title and instrutions.
      const payload = action.payload
      const targetAngles = payload.targetAngles
      state.targetAngles = targetAngles
    },
    setBinNum: ( state, action ) => {
      const binNum = action.payload.binNum
      state.binNum = binNum
    },
    setShowIntro: (state, action) => {
      const showIntro = action.payload.showIntro
      state.showIntro = showIntro
    }
  } // end reducers
}) // end phaseSlice

/*
 * Exports
 */
// Actions:
export const {
  setPhaseParams,
  setBinNum,
  setShowIntro,
} = phaseSlice.actions

// Selectors:
export const selectBinNum = state => state.root.phase.binNum
export const selectBinCount = state => state.root.phase.binCount
export const selectShowIntro = state => state.root.phase.showIntro
export const selectPhaseName = state => state.root.phase.phaseName
export const selectIntroModal = state => state.root.phase.introModal

export default phaseSlice.reducer
