import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  experimentName: 'development',
  phases: [
    {
      phaseName: 'Phase 1',
      targetAngles: [0, 180],
      rotation: 0,
      binCount: 2,
    },
    {
      phaseName: 'Phase 2',
      targetAngles: [90, 270],
      rotation: 45,
      binCount: 1,
    },
  ], // end phases
  phaseIndex: 0,
  showConsent: true, // this is important
  consentModal: {},
  showExit: false,
  exitModal: {},
  history: {}
} // end initialState

export const experimentSlice = createSlice({
  name: 'experiment',
  initialState: initialState,
  reducers: {
    setPhaseIndex: (state, action) => {
      const phaseIndex = action.payload.phaseIndex
      state.phaseIndex = phaseIndex
    },
    setShowConsent: (state, action) => {
      const showConsent = action.payload.showConsent
      state.showConsent = showConsent
    },
    setShowExit: (state, action) => {
      const showExit = action.payload.showExit
      state.showExit = showExit
    },
    setPartId: (state, action) => {
      const partId = action.payload.partId
      state.partId = partId
    },
    initializeHistory: (state, action) => {
      const partId = state.partId
      const experimentName = state.experimentName
      state.history = { experimentName, partId }
    },
    initializeExperiment: (state, action) => {
      const input = action.payload.input
      const {
        phases,
        scene,
        consentModal,
        exitModal,
        experimentName,
      } = input

      state.experimentName = experimentName
      state.phases = phases
      state.scene = scene

      state.consentModal = consentModal
      // Note name change.
      state.exitText = exitModal
    }

  } // end reducers
}) // end experimentSlice
export default experimentSlice.reducer

export const {
  setPhaseIndex,
  setShowConsent,
  setShowExit,
  setPartId,
  initializeHistory,
  initializeExperiment,
} = experimentSlice.actions

export const selectPhases = state => state.root.experiment.phases
export const selectPhaseIndex = state => state.root.experiment.phaseIndex
export const selectShowConsent = state => state.root.experiment.showConsent
export const selectShowExit = state => state.root.experiment.showExit
export const selectConsentText = state => state.root.experiment.consentModal.text
export const selectConsentPattern = state => state.root.experiment.consentModal.pattern
export const selectExitText = state => state.root.experiment.exitText
