import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { registerListener } from '../registerListener'
const style = {
  // cursor: 'none'
}

const ExitModal = ({
  show,
  onShutDown,
  text,
}) => {
  let unregisterPointerUp
  // show = show == undefined ? true : show
  /*
   * Handlers
   */
   // Modal's shut down callback. Unregister the listener and calls the onShutDown prop.
  const shutDown = () => {
    unregisterPointerUp()
    onShutDown()
  }
  // Create the pointerclick listener
  const onShow = () => {
    unregisterPointerUp = registerListener('pointerup', shutDown)
  }

  return (
    <Modal
            show={show}
            onShow={onShow}
            centered={true}
            size='lg'
            animation={true}
            backdrop={'static'}
            style={style}
          >
      <Modal.Header >
        <Modal.Title>
          { text && text.title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML = {text && {__html: text.body }} />
      </Modal.Body>
    </Modal>
  )
}
export default ExitModal
