const input =
{
  "experimentName": "Develop Experiment",

  "consentModal": {
    "text": {
      "title": "Consent Modal",
      "body":
        "<p>Experiment instructions...</p>" +
        "<p>Enter your participant id and type return to start the experiment.</p>",
      },
    "pattern": "^[a-zA-Z]+[0-9]{3}$"
  },

  "scene": {
    "resizeModal": {
      "title": "Resize Modal",
      "body":
      "<p>Do not resize browser window during trial.</p>" +
      "<p>Click in the blue cricle to exit and redo trial.</p>"
    },
  }, // end scene

  "phases": [
    { // phase
      "phaseName": "Phase 1",
      "targetAngles": [ 0, 90, 180, 270],
      "binCount": 1,
      "rotation": 0,

      "feedbackModal": {
        "title": "Success!",
        "bounds": [350, 600],
        "slowText": "Too slow! Go faster.",
        "fineText": "Correct speed! Good.",
        "fastText": "Too fast! Go slower.",
        "displayTime": 500
      },

      "introModal": {
        "title": "Phase 1",
        "body":
          "<p>Phase instruction...</p>" +
          "<p>Click in the blue circle to exit and start this phase.</p>"
      },
    }, // end phase

    { // phase
      "phaseName": "Phase 2",
      "targetAngles": [ 0, 90, 180, 270],
      "binCount": 1,
      "rotation": 45,

      "feedbackModal": {
        "title": "Success!",
        "bounds": [350, 600],
        "slowText": "Too slow! Go faster.",
        "fineText": "Correct speed! Good.",
        "fastText": "Too faster! Go slower.",
        "displayTime": 500
      },

      "introModal": {
        "title": "Phase 2",
        "body":
          "<p>Phase instruction...</p>" +
          "<p>Click in the blue circle to exit and start this phase.</p>"
      },
    } // end phase

  ], // end phases

  "exitModal":{
    "title": "Exit Modal",
    "body": "<p> Thank you. </p>"
  }
}
export default input
