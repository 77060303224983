// import action constants used by this cross slice reducer
import {
  SET_BIN_PARAMS,
  SET_ROTATION,
  SET_TARGET_ANGLES,
  READ_BIN_HISTORY,
} from '../crossSliceReducer'
/*
 * Phase Cross Slice reducers
 * cannot mutate state
 */
 /*
  * The setBinParamsReducer cross case reducer assigns Bin's:
  *    * targetAngles
  *    * rotation
  */
export const setBinParamsReducer = (phase, action, bin) => {
 // extract from Phase state or action.payload
 // extract from phase state
 const feedbackModal = phase.feedbackModal
 return { ...bin, feedbackModal}
}
export const setBinTargetAnglesReducer = (phase, action, bin) => {
 const targetAngles = phase.targetAngles
 return { ...bin, targetAngles}
}
export const setRotationReducer = (phase, action, scene) => {
  const rotation = phase.rotation
  return {...scene, rotation}
}
export const readBinHistoryReducer = (phase, action, bin) => {
  const trials = [...bin.history]
  const history = {...phase.history, trials}
  return {...phase, history}
}


 /*
  * Action createors
  */
export const setBinParams = payload => {
  return { type: SET_BIN_PARAMS, payload }
}
export const setRotation  = payload => {
  return { type: SET_ROTATION, payload}
}
export const setTargetAngles = payload => {
  return {type: SET_TARGET_ANGLES, payload}
}
export const readBinHistory = payload => {
  return {type: READ_BIN_HISTORY, payload}
}
