import React from 'react'
import Modal from 'react-bootstrap/Modal'


const style = {
  cursor: 'none'
}

const FeedbackModal = ({
  show,
  onShutDown,
  wasInTarget,
  feedbackTime,
  params,
}) => {
  /*
   * Handlers
   */
   // Callback for Modal's call back. Clears the timer and calls the onShutDown prop.
  const shutDown = () => {
    onShutDown()
    clearTimeout(timerId)
  }
  // Create timer for modal.
  const TIMER_INTERVAL = 1000  // Time to show the feedback modal in msec
  const displayTime = params ? params.displayTime : TIMER_INTERVAL
  let timerId
  const onShow = () => {
    timerId = setTimeout(shutDown, displayTime)
  }

  let feedback
  if (params.bounds) {
    if( feedbackTime < Math.min(...params.bounds)){
      feedback = params.fastText
    } else if( feedbackTime > Math.max(...params.bounds)){
      feedback = params.slowText
    } else {
      feedback = params.fineText
    }
  }

  return (
    <Modal
            show={show}
            onShow={onShow}
            centered={true}
            size='lg'
            animation={true}
            style={style}
          >
      <Modal.Header>
        <Modal.Title>
          { params && params.title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{ feedback }</p>
        <p>Feed back time is  { feedbackTime } msec.</p>
      </Modal.Body>
    </Modal>
  )
}
export default  FeedbackModal
