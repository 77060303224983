import React from 'react';
// import Scene from './features/scene/Scene'
// import Bin from './features/bin/Bin'
// import Phase from './features/phase/Phase'
import Experiment from './features/experiment/Experiment'


function App() {

  return (
    <Experiment />
  )
}

export default App;
