import React from 'react'
import {targetStyle, inTargetStyle} from './styleConstants.js'

export default function Target ({ x, y, radius, isInTarget}) {
  return (<circle
    cx={x}
    cy={y}
    r={radius}
    style={isInTarget ? inTargetStyle : targetStyle}
  />)
}
