import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import store from '../../app/store'

import {
  setSceneParams,
  setPhaseParams,
  readPhaseHistory,
} from './experimentCrossSlice'

import {
  // Actions:
  setPhaseIndex,
  setShowConsent,
  setShowExit,
  setPartId,
  initializeHistory,
  initializeExperiment,
  // Selectors:
  selectPhases,
  selectPhaseIndex,
  selectShowConsent,
  selectShowExit,
  selectConsentText,
  selectConsentPattern,
  selectExitText,
} from './experimentSlice'

import { setShowIntro } from '../phase/phaseSlice'

import Phase from '../phase/Phase'
import Consent from './ConsentModal'
import Exit from './ExitModal'

import input from './inputJson.js'

// eslint-disable-next-line
const getHistory = () => {return store.getState().root.experiment.history}

const Experiment = ( ) => {
  // console.log('In Experiment')
  const dispatch = useDispatch()

  /*
   * Handlers
   */
  const onConsentShutDown = (partId) => {
    dispatch( setPartId( { partId: partId}))
    dispatch( setSceneParams())
    dispatch( setPhaseParams( { phaseIndex: 0 }))
    dispatch( initializeHistory())
    dispatch( setShowConsent( { showConsent: false}))
    dispatch( setShowIntro( { showIntro: true }))
  } // end onConsentShutDown
  const onPhaseDone = () => {
     dispatch( readPhaseHistory())
     if ( phaseIndex < phases.length - 1 ) {
       const nextPhaseIndex = phaseIndex + 1
       dispatch( setPhaseIndex( { phaseIndex: nextPhaseIndex }))
       dispatch( setPhaseParams( { phaseIndex: nextPhaseIndex }))
       dispatch( setShowIntro( { showIntro: true }))
     } else {
       dispatch( setShowExit( { showExit: true}))
       // console.log( JSON.stringify( getHistory(), 4 ))
       // Can use https://jsonlint.com/
     }
   } // end onPhaseDone
   const onExitShutDown = () => {
     dispatch( setShowExit( { showExit: false }))
   } // end onExitShutDone

  /*
   * Hooks
   */
   // Initializing useEffect
   useEffect( () => {
     dispatch( initializeExperiment( { input }))
   },[]) // end useEffect

  /*
   * Selectors
   */
  const phases = useSelector(selectPhases)
  const phaseIndex = useSelector(selectPhaseIndex)

  const showConsent = useSelector(selectShowConsent)
  const consentText = useSelector(selectConsentText)
  const consentPattern = useSelector(selectConsentPattern)

  const showExit = useSelector(selectShowExit)
  const exitText = useSelector(selectExitText)

  return (
    <>
      <Phase onPhaseDone={onPhaseDone} />
      <Consent
        show={showConsent}
        onShutDown={onConsentShutDown}
        text={consentText}
        pattern={consentPattern}
      />
      <Exit
        show={showExit}
        onShutDown={onExitShutDown}
        text={exitText}
      />
    </>
  )

} // end Experiment
export default Experiment
