import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

// From Bin's slice and cross slice reducers
import {
  readTrialHistory,
  setTargetAngle,
} from './binCrossSlice'

import {
  // Actions:
  processTrial,
  setShowFeedback,
  // startBin,
  setTrialNum,

  // Selectors
  selectShowFeedback,
  selectWasInTarget,
  selectFeedbackTime,
  selectTrialNum,
  selectTargetAngles,
  selectFeedbackModal,
  // selectIsRunning,
} from './binSlice'

// From Scene and sceneSlice
import {
  sceneRun,
} from '../scene/sceneSlice'
import Scene from '../scene/Scene'

import Feedback from './FeedbackModal'

/*
 * Bin controls Scene by setting Scene's tragetAnle.
 * Bin has a array, targetAngles, that it shuffles and then
 * iterators. Bin expects an onTrialDone callback from Phase.
 */
const Bin = ( { onBinDone } ) => {
  // console.log('In Bin')
  const dispatch = useDispatch()
  /*
   * Handlers
   */
   // Called by Scene, finish ending the trial
  const onTrialDone = () => {
    dispatch( sceneRun( { isRunning: false } )) // to sceneSlice
    dispatch( readTrialHistory()) // to binCrosSlice
    dispatch( processTrial())  // to binSlice
    dispatch( setShowFeedback({ showFeedback: true })) // to binSlice
    // setShow(true)
  } // end onTrialDone
  // Called by Feedback, prepare for the next trial and run Scene
  const onFeedbackShutDown = () => {
    dispatch( setShowFeedback({ showFeedback: false })) // to binSlice
  // setShow(false)
    if ( trialNum < targetAngles.length - 1 ) {
      dispatch( setTrialNum( { trialNum: trialNum + 1} ))
      dispatch( setTargetAngle())
      dispatch( sceneRun( { isRunning: true } )) // to sceneSlice
    } else {
      onBinDone()
    }
  } // end onFeedbackShutDown
  /*
   * Selectors
   */
   // Used in Feedback
  const showFeedback = useSelector(selectShowFeedback)
  const wasInTarget = useSelector(selectWasInTarget)
  const feedbackTime = useSelector(selectFeedbackTime)

  const trialNum = useSelector(selectTrialNum)
  const targetAngles = useSelector(selectTargetAngles)
  const  feedbackParams = useSelector(selectFeedbackModal)

  return (
    <>
      <Scene onTrialDone={onTrialDone} />
      <Feedback
        show={showFeedback}
        onShutDown={onFeedbackShutDown}
        wasInTarget={wasInTarget}
        feedbackTime={feedbackTime}
        params={feedbackParams}
      />
    </>
  )

} // end function Bin
export default Bin
