import React from 'react'
import {cursorStyle} from './styleConstants.js'

export default function Cursor ({ x, y, radius, isVisible }) {
  if(isVisible) {
    return (<circle
      cx={x}
      cy={y}
      r={radius}
      style={cursorStyle}
    />)
  } else {  // isVisible is false. Draw nothing.
    return (<> </>)
  }
}
