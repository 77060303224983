import React from 'react'

// import {fillStyle} from './styleConstants.js'
// export default function Circle ({ x, y, radius }) {
//   return (<circle
//     cx={x}
//     cy={y}
//     r={radius}
//     style={fillStyle}
//   />)
// }

export default function Circle ({ x, y, radius, style }) {
  return (<circle
    cx={x}
    cy={y}
    r={radius}
    style={style}
  />)
}
