import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import store from '../../app/store'

import {
  // Constants for stage of the trial
  // PAUSED,
  // ACQUIRE,
  RETURN,
  // RECOVER,
  // Actions/Case Reducers:
  // setParams,
  bndRectChange,
  pointerMove,
  pointerDown,
  pointerUp,
  keyUp,
  setRedoCount,
  setResizeCount,
  setShowResize,
  tick,
  // Selects:
  selectBndRect,
  selectStart,
  selectCursor,
  selectTarget,
  selectIsInTarget,
  // selectIsInStart,
  // selectFeedbackCircle,
  // selectSceneBox,
  selectIsCursorVisible,
  selectIsPointerVisible,
  selectShowResize,
  selectResizeModal,
} from './sceneSlice'

// Styles for .page, .scene-container and .scene
import styles from './scene.module.css'

import { registerListener } from '../registerListener'

// Components
import Cursor from './Cursor'
import Target from './Target'
import Start from './Circle'
// import FeedbackCircle from './Circle'
// import Frame from './Rect'
import {strokeStyle} from './styleConstants.js'

// import Resize from './ResizeModal'
import Resize from '../startmodal/StartModal'

// const TIMER_FREQ = 10 // Timer frequency for development
const TIMER_FREQ = 60 // Timer frequency for porduction
const getIsInStart = () => {return store.getState().root.scene.isInStart}
const getStage = () => {return store.getState().root.scene.stage}

/*
 * Scene component handles drawing:
 *   * Frame
 *   * Start
 *   * Target
 *   * Cursor
 * Frame response to pageBndRect prop.
 * Cursor response to the action creators:
 *   * bndRectChange
 *   * pointerMove
 *   * pointerDown
 *   * pointerUp
 *   * tick - from the timer
 * Bin controls Scene by dipatching
 *    * setParams -
 *    * setTargetAngle
 *    * sceneRun - Boolean, true for running and false to stop
 */
export default function Scene({onTrialDone }){
  // console.log('In Scene')

  /*
   * handlers/listeners, see the initializing useEffect.
   */
  const onBndRectChange = () => {
    // console.log('In onBndRectChange')
    const DOMRect = sceneContainer.current.getBoundingClientRect()
    // DomRect returned from getBoundingClientRect is not sieralizable.
    // Should create the object as below:
    const {x, y, width, height} = DOMRect // extract what need from DOMRect
    const bndRect = {x, y, width, height} // Use shorthand to create bndRect
    const payload = { bndRect }
    dispatch(bndRectChange(payload))
  }
  const onPointerMove = (e) => {
    // const stage = getStage()
    // console.log(stage)
    const pointerLoc = {x: e.pageX, y: e.pageY}
    const payload = {pointerLoc}
    dispatch(pointerMove(payload))
  }
  const onPointerDown = (e) => {
      const pointerLoc = {x: e.pageX, y: e.pageY}
      const payload = {pointerLoc }
      dispatch( pointerDown( payload))
    // } // end if isInStart
  } // end onPointerDown
  const onPointerUp = (e) =>  {
    // Need to get stage before dispatch because dispatch can change stage.
    const stage = getStage()
    dispatch( pointerUp()) // dispatch to sceneSlice
    // Also the pointer should be in start for trial to be done.
    const isInStart = getIsInStart()
    if( stage === RETURN && isInStart ){
      dispatch( setRedoCount( { redoCount: 0}))
      dispatch( setResizeCount( { resizeCount: 0}))
      onTrialDone && onTrialDone() // trial done handler dispatches to binSlice
    }
  } // end onPointerUp
  const onKeyUp = (e) => {
    if (e.code === 'Space') {
      dispatch( keyUp())
    }
  } // end onKeyUp
  const onTimer = () => {
    // Note cannot make function calls in the dispatch
    const time = Date.now()
    const payload = {time}
    dispatch(tick(payload))
  } // end onTimer
  const onResizeShutDown = () => {
    dispatch( setShowResize( { showResize: false} ))
  }

  /*
   * Hooks
   */
  // For dispataching action creators
  const dispatch = useDispatch()

  // Initial useEffect for setting up listeners and timers.
  // eslint-disable-next-line
  useEffect(() => {
    const unregisterResizeListener = registerListener('resize', onBndRectChange)
    const unregisterPointerMove = registerListener('pointermove', onPointerMove)
    const unregisterPointerDown = registerListener('pointerdown', onPointerDown)
    const unregisterPointerUp = registerListener('pointerup', onPointerUp)
    const unregisterKeyUp = registerListener('keyup', onKeyUp)
    const timerId = setInterval(onTimer, 1000/TIMER_FREQ)
    // Need to call it once to initialize
    onBndRectChange()

    return () => {
      unregisterResizeListener()
      unregisterPointerMove()
      unregisterPointerDown()
      unregisterPointerUp()
      unregisterKeyUp()
      clearInterval(timerId)
    }
  },[]) // end initializing useEffect

  // Reference for determining bounding rectange
  const sceneContainer = useRef()

  // Selectors, these are values from state needed for drawing.
  const bndRect = useSelector(selectBndRect)
  // const sceneBoxProps = useSelector(selectSceneBox)
  const startProps = useSelector(selectStart)
  const cursorProps = useSelector(selectCursor)
  const targetProps = useSelector(selectTarget)
  const isInTarget = useSelector(selectIsInTarget)
  // const feedbackCircleProps = useSelector(selectFeedbackCircle)
  const isCursorVisible = useSelector(selectIsCursorVisible)
  const isPointerVisible = useSelector(selectIsPointerVisible)

  const showResize = useSelector(selectShowResize)
  // Note name change
  const resizeText = useSelector(selectResizeModal)

  return (
    <>
      <div className={styles.page} >
        <div className={styles.sceneContainer + ' ' +
          ( isPointerVisible ? styles.showPointer : styles.hidePointer )}
          ref={sceneContainer}>
          <svg width={bndRect && bndRect.width}
               height={bndRect && bndRect.height}
               className={styles.scene}>
            {/* <Frame {...sceneBoxProps} style={strokeStyle}/> */}
            <Start {...startProps} style={strokeStyle} />
            {/*   < FeedbackCircle {...feedbackCircleProps} style={strokeStyle} /> */}
            <Target {...targetProps} isInTarget={isInTarget} />
            <Cursor {...cursorProps} isVisible={isCursorVisible}/>
          </svg>
        </div>
      </div>
      <Resize
        show={showResize}
        onShutDown={onResizeShutDown}
        text={resizeText}
        startProps={startProps}
      />
    </>
  )
}
