import React, { useRef, useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

// import { registerListener } from '../registerListener'
const style = {
  // cursor: 'none'
}
const ConsentModal = ({
  text,
  pattern,
  show,
  onShutDown,
}) => {
  useEffect(()=>{
    inputNode.current.focus()
  },[])
  const inputNode = useRef()

  /*
   * Handlers
   */
  const onSubmit = (e) =>{
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget
    if (form.checkValidity() === true){
      onShutDown(partId)
    }
    setValidated(true)
  }

  const onChange = (e) => {
    const value = e.target.value
    setPartId(value)
  }

  // const pattern = '^[a-zA-Z]+[0-9]{3}$'

  const [validated, setValidated] = useState(false)
  const [partId, setPartId] = useState('')

  return (
    <Modal
      show={show}
      centered={true}
      size='lg'
      animation={true}
      backdrop={'static'}
      style={style}
    >
      <Modal.Header >
        <Modal.Title> { text && text.title } </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <div dangerouslySetInnerHTML = {text && {__html: text.body }} />
        <Form
          noValidate
          validated={validated}
          onSubmit={onSubmit}
        >
         <Form.Group controlId='formGroupPartID' >
          <Form.Label > Enter ID: </Form.Label>
            <Form.Control
              required
              pattern={pattern}
              type='text'
              ref={inputNode}
              onChange={onChange}
            />
            <Form.Control.Feedback type='invalid'>
              Your participant id starts with any number of alphabet characters
              and ends with three digits.
            </Form.Control.Feedback>
            <Form.Control.Feedback type='valid'>
              Looks good!
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default ConsentModal
