import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// From Phase's slice and cross slice reducers
import {
  // setBinParams,
  setRotation,
  setTargetAngles,
  readBinHistory,
  setBinParams
} from './phaseCrossSlice'

import {
  // Actions:
  // setPhaseParameters,
  setBinNum,
  setShowIntro,
  // Selectors:
  selectBinNum,
  selectBinCount,
  selectShowIntro,
  // selectPhaseName,
  selectIntroModal,
} from './phaseSlice'

import {
  // selectors:
  selectStart
} from '../scene/sceneSlice'

// From Bin's slice and cross slice
import { binRun, startBin, } from '../bin/binSlice'
import{ setTargetAngle } from '../bin/binCrossSlice'

// From Scene slice
import { sceneRun } from '../scene/sceneSlice'

import Bin from '../bin/Bin'
// import Intro from './IntroModal'
import Intro from '../startmodal/StartModal'

const Phase = ({ onPhaseDone }) => {
  // console.log('In Phase')
  const dispatch = useDispatch()
  /*
   * Handlers
   */
   // Called by Intro, start the Phase
   const onIntroShutDown = () => {
     dispatch( setShowIntro( { showIntro: false }))
     dispatch( setTargetAngles())
     dispatch( setRotation())
     dispatch( setBinParams())
     // Start Bin and Scene
     dispatch( startBin()) // to binSlice
     dispatch( setTargetAngle()) // to binCrosSlice
     dispatch( sceneRun( {isRunning: true} )) // to sceneSlice
   }
   // Called by Bin, counts bins.
   const onBinDone = () => {
     if ( binNum < binCount ) {
       dispatch( setBinNum ( { binNum: binNum + 1 } ))
       // Start Bin and Scene
       dispatch( startBin()) // to binSlice
       dispatch( setTargetAngle()) // to binCrosSlice
       dispatch( sceneRun( {isRunning: true} )) // to sceneSlice
     } else {
       dispatch( binRun( { isRunning: false }))
       dispatch( readBinHistory())
       onPhaseDone()
     }
   } // end onBinDone
  /*
   * Hooks
   */
  /*
   * Selectors
   */
   const binNum = useSelector(selectBinNum)
   const binCount = useSelector(selectBinCount)
   const showIntro = useSelector(selectShowIntro)
   // const phaseName = useSelector(selectPhaseName)
   const startProps = useSelector(selectStart)
   // Note name change.
   const introText = useSelector(selectIntroModal)

  return (
    <>
      <Bin onBinDone={onBinDone}/>
      <Intro
        show={showIntro}
        onShutDown={onIntroShutDown}
        text={introText}
        startProps={startProps}
      />
    </>
  )
} // end Phase
export default Phase
