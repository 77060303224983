
import {
  READ_TRIAL_HISTORY,
  SET_TARGET_ANGLE,
} from '../crossSliceReducer'
/*
 * Bin Cross Slice reducers
 * cannot mutate state
 */
 /*
  * The readTrialHistoryReducer case reducer moves the scene's history
  * to bin's history
  */
export const readTrialHistoryReducer = (bin, action, scene) => {
  if (bin.isRunning) {
    const historyEntry = makeBinHistoryEntry(scene.history)
    const history = bin.history ? [...bin.history, historyEntry] : [historyEntry]
    return {...bin, history}
  } else {
    return bin
  }
}
/*
 * The setTargetAngleReducer cross case reducer assigns scene's targetAngle
 */
export const setTargetAngleReducer = (bin, action, scene) => {
    const trialNum = bin.trialNum
    const targetAngle = bin.targetAngles[trialNum]
    return { ...scene, targetAngle}
}

/*
 * Action createors
 */
export const readTrialHistory = payload =>{
  return { type: READ_TRIAL_HISTORY, payload }
}
export const setTargetAngle = payload =>{
  return { type: SET_TARGET_ANGLE, payload }
}


/*
 * Helper functions
 */
 const makeBinHistoryEntry = (sceneHistory) => {
   if (sceneHistory && sceneHistory[0]){
     // extract the constants for the trial
     const {
       bndRect,
       targetAngle,
       start,
       target,
       feedbackCircle,
       redoCount,
       resizeCount,
     } = sceneHistory[0]

     // extract arrays over time
     const select = entry => {
       const {
         time,
         cursor,
         offsetLoc,
         pointerLoc,
         isInTarget,
         isInFeedbackCircle,
         isInStart,
       } = entry
       return {
         time,
         cursor,
         offsetLoc,
         pointerLoc,
         isInTarget,
         isInFeedbackCircle,
         isInStart,
       }
     } // end select
     const timeVar = sceneHistory.map(select)

     return {
       bndRect,
       targetAngle,
       start,
       target,
       feedbackCircle,
       redoCount,
       resizeCount,
       timeVar,
     }
   } else {
     console.log('In getHistoryEntry, sceneHistory is empty')
     return undefined
   }
 } // end getHistory
