
export const strokeStyle = {
  stroke: 'rgb(236, 240, 241)',
  strokeWidth: '3px',
  fill: 'none'
}

export const cursorStyle = {
  fill: 'rgb(236, 240, 241)',
}

export const targetStyle = {
  fill: 'rgb(63, 136, 191)',
}

export const inTargetStyle = {
  fill: 'rgb(65, 191, 63)',
}
