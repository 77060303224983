import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  history: [],
  showFeedback: false,
  wasInTarget: false,
  // avgVelocity: undefined,
  targetAngles: [],
  trialNum: 0,
  feedbackTime: undefined,
  isRunning: false, // controls reading Scene's history and writing history
  feedbackModal: {},
}

export const binSlice = createSlice({
  name: 'bin',
  initialState: initialState,
  reducers: {
    /*
     * The processTrial case reducer is dispatched after binCrosSlice,
     * readTrialHistory, has consturted the entry for bin's history.
     * It calculates and set states for wasInTarget and avgVelocity for
     * trial.
     */
    processTrial: ( state ) => {
      if (state.isRunning) {
        state.wasInTarget = getWasInTarget(state)
        // state.avgVelocity = getAvgVelocity(state)
        const feedbackTime = getFeedbackTime(state)
        state.feedbackTime = feedbackTime
        // Specaial favor for Kevin
        const curHistory = getCurHistory(state)
        curHistory.movementTime = feedbackTime
      }
    },
    /*
     * The setShowFeedback case reducer controls the feedback modal.
     */
    setShowFeedback: ( state, action ) => {
      if (state.isRunning) {
        const showFeedback = action.payload.showFeedback
        state.showFeedback = showFeedback
      }
    },
    /*
     * The setParams case reduce sets Bin's parameters
     *    * targetAngles
     */
     setBinParameters: ( state, action ) => {
       const payload = action.payload
       const targetAngles = payload.targetAngles
       state.targetAngles = targetAngles
     },
    /*
     * The startBin case reduce initializes Bin targetAngle
     */
     startBin: ( state ) => {
       shuffleArray(state.targetAngles)
       state.trialNum = 0
       state.isRunning = true
     },
     /*
      *
      */
      binRun: ( state, action ) => {
        const isRunning = action.payload.isRunning
        state.isRunning = isRunning
      },
      setTrialNum: ( state, action ) => {
        const trialNum = action.payload.trialNum
        state.trialNum = trialNum
      },
  } // end reducers
}) // end binSlice



/*
 * helper Function
 */
 /*
  * Fisher-Yates or Durstenfeld shuffle, see
  * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  */
const shuffleArray = (array) => {
   for (let i = array.length - 1; i > 0; i--) {
     const j = Math.floor(Math.random() * (i + 1));
     [array[i], array[j]] = [array[j], array[i]];
   }
}

const getCurHistory = (state) => {
 const history = state.history
 const curHistory = history[history.length - 1]
 return curHistory
}

const getFeedbackTime = (state) => {
  const curHistory = getCurHistory(state)
  const timeVar = curHistory.timeVar

  // Construct isInFeedbackCircle array by selecting from timeVar entry object
  const isInFeedbackCircle = timeVar.map( e => {
    const {isInFeedbackCircle} = e
    return isInFeedbackCircle
  })
  // Construt isInTarget array by selecting from timeVar entry object
  const isInTarget = timeVar.map( e => {
    const {isInTarget} = e
    return isInTarget
  })
  // Construct time array by selecting from timeVar entry object
  const time = curHistory.timeVar.map( e => {
    const {time} = e
    return time
  })
  // Find the index in isInFeedbackCircle and extract time
  // const index = isInFeedbackCircle.findIndex( e => !e )
  const startTime = time[ isInFeedbackCircle.findIndex( e => !e ) ]
  const endTime = time[ isInTarget.findIndex( e => e ) ]
  const duration = endTime - startTime
  return duration
} // end getFeedbackTime

// eslint-disable-next-line
const getAvgVelocity = (state) => {
 const curHistory = getCurHistory(state)
 const timeVar = curHistory.timeVar
 const N = timeVar.length-1
 const x = timeVar[N].cursor.x - timeVar[0].cursor.x
 const y = timeVar[N].cursor.y - timeVar[0].cursor.y
 const length = Math.abs(x) + Math.abs(y)
 const deltaTime = timeVar[N].time - timeVar[0].time
 return (length/deltaTime)*1000
} // end getAvgVelocity


const getWasInTarget = (state) => {
  const curHistory = getCurHistory(state)
  // callback for constructing isInTarget array
  const select = entry => {
    const { isInTarget } = entry
    return isInTarget
  }
  // create the isIntarget array
  const isInTarget = curHistory.timeVar.map(select)

  // check if isInTarget was every true.
  return isInTarget.includes(true)
} // end getWasInTarget

/*
 * Exports
 */
 // Actions:
export const {
  processTrial,
  setShowFeedback,
  setBinParameters,
  startBin,
  // nextTrial,
  setTrialNum,
  binRun,
} = binSlice.actions

// Selectors:
export const selectShowFeedback = state => state.root.bin.showFeedback
export const selectWasInTarget = state => state.root.bin.wasInTarget
export const selectAvgVelocity = state => state.root.bin.avgVelocity
export const selectFeedbackTime = state => state.root.bin.feedbackTime

export const selectTrialNum = state => state.root.bin.trialNum
export const selectTargetAngles = state => state.root.bin.targetAngles
export const selectIsRunning = state => state.root.bin.isRunning
export const selectFeedbackModal = state => state.root.bin.feedbackModal

export default binSlice.reducer
