
/*
 * Geometry Constants
 * Nominal sizes
 */
export const nomSceneSize = 700
export const nomSceneCenter = {x: 350, y: 350}
export const nomCursorRadius = 20
export const nomTargetRadius = 40
export const nomMargin = 30 // no Frame // 20 // with Frame
export const nomPadding = 0 // no Frame // 13 // with Frame

export const nomStartRadius = nomTargetRadius
export const nomFeedbackRadius = nomStartRadius

/*
 * Rotation
 */
export const rotation = 45
