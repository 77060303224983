import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'

// All the slice reducers
import sceneReducer from '../features/scene/sceneSlice'
import binReducer from '../features/bin/binSlice'
import phaseReducer from '../features/phase/phaseSlice'
import experimentReducer from '../features/experiment/experimentSlice'

import crossSliceReducer from '../features/crossSliceReducer'

// Combine all the slice reducer
const combineReducer = combineReducers({
  scene: sceneReducer,
  bin: binReducer,
  phase: phaseReducer,
  experiment: experimentReducer,
})

// Integrating combine reducer and cross slice reducer
const rootReducer = (state, action) => {
  const intermediateState = combineReducer(state, action)
  const finalState = crossSliceReducer(intermediateState, action)
  return finalState
}

// Make the store
const store = configureStore({
  reducer: {
    root: rootReducer
  },
});

export default store
