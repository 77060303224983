// import action constants used by this cross slice reducer
import {
  SET_PHASE_PARAMS,
  READ_PHASE_HISTORY,
  SET_SCENE_PARAMS,
} from '../crossSliceReducer'

/*
 * Action createors
 */
export const setPhaseParams = payload => {
  return { type: SET_PHASE_PARAMS, payload }
}
export const readPhaseHistory = payload => {
  return { type: READ_PHASE_HISTORY, payload }
}
export const setSceneParams = payload => {
  return { type: SET_SCENE_PARAMS, payload }
}

/*
 * Experiment Cross Slice reducers
 * cannot mutate state
 */
 /*
  * Set the paramerters for Phase from Experiment's phases state
  * using phaseIndex from action.payload
  */
export const setPhaseParamsReducer = (experiment, action, phase) => {
  const phaseIndex = action.payload.phaseIndex
  const {
    phaseName,
    targetAngles,
    rotation,
    binCount,
    introModal,
    feedbackModal,
  } = experiment.phases[phaseIndex]

  const history = experiment.phases[phaseIndex]

  return {...phase,
    phaseName,
    targetAngles,
    rotation,
    binCount,
    introModal,
    feedbackModal,
    history,
  }
}
/*
 * Set the paramerters for scene from Experiment's scene state.
 * For now there is only resizeModal.
 *
 */
export const setSceneParamsReducer = (experiment, action, scene) => {
  const {
    resizeModal,
  } = experiment.scene
  return {...scene,
    resizeModal,
  }
}
/*
 * Reads Phase's history. Note that both "phases" and "history" must be
 * created and prior "phases" and "history" to not mutate the state.
 */
export const readPhaseHistoryReducer = ( experiment, action, phase ) => {
  const phases = experiment.history.phases ?
    [...experiment.history.phases, phase.history] : [phase.history]
    const history = {...experiment.history, phases}
    return {...experiment, history}
}
