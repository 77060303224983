import { createSlice } from '@reduxjs/toolkit'
// import store from '../../app/store' // I cannot do this. I think because store is not made yet.

import getDrawState from './engine/drawState'
import { getInBoxCircle } from './engine/drawUtils'

/*
 * The slice reducer sceneSlice controls the state for the scene.
 * Has reducers:
 *   * bndRectChange - responds to resize
 *   * pointerMove - responds to ponitermove and sets pointerLoc
 *   * pointerDown - responds to pointerdown. It:
 *                   * sets trial stage to Acquire
 *                   * calculates delta
 *                   * creates history array and record state
 *   * pointerUp - responds to pointerup and toogles set trial stage
 *   * tick - reponds to a timer event. It:
 *              * set all the drawing states
 *              * record the time
 *              * if stage is not PAUSED records state in history state.
 */

 /*
  * Constants for stage values during a trial
  */
export const PAUSED = 'PAUSED'
export const ACQUIRE = 'ACQUIRE'
export const RETURN = 'RETURN'
export const RECOVER = 'RECOVER'

const initialState = {
  stage: PAUSED,
  isRunning: false,
  targetAngle: undefined,
  isInTarget: false,
  isInFeedbackCircle: true,
  isInStart: true,
  rotation: 0,
  start: {},
  cursor: {},
  target: {},
  time: undefined,
  pointerLoc: [],
  offsetLoc: [],
  delta: undefined,
  history: [],
  bndRect: {},
  feedbackCircle: {},
  isCursorVisible: true,
  isPointerVisible: false,
  redoCount: 0,
  resizeCount: 0,
  showResize: false,
  resizeModal: {},
}

export const sceneSlice = createSlice({
  name: 'scene',
  initialState: initialState,
  reducers: {
    /*
     * Action sceneRun turns off and on the trial. It just sets boolean isRunning.
     * The boolean isRunning just allows pointerUp and pointerDown action to
     * run.
     *
     * Used by Bin.
     */
    sceneRun: (state, action) => {
      const { isRunning } = action.payload
      state.isRunning = isRunning
    },
    /*
     * Action setParams sets all the parameters for the trial.
     *   * targetAngle - in degrees, the position of the target.
     *   * rotation
     * Used by Bin.
     */
    setSceneParams: (state, action) => {
      const {
        targetAngle,
        // rotation,
      } = action.payload
      state.targetAngle = targetAngle
    },
    /*
     * Action bndRectChange just sets the bndRect. The rectange is the window size.
     * It is needed because start, cursor and target are drawn to scale.
     */
    bndRectChange: (state, action) => {
      const { bndRect } = action.payload
      state.bndRect = bndRect
      if( state.stage === ACQUIRE || state.stage === RETURN ) {
        state.resizeCount++
        setRecover(state)
        state.showResize = true
      }
    },
    pointerMove: (state, action) => {
      const { pointerLoc } = action.payload
      state.pointerLoc = pointerLoc
      const isInTarget = state.isInTarget
      if( isInTarget ){ // The only way to get to RETURN stage
        state.stage = RETURN
      }
    },
    pointerDown: (state, action) => {
      // only have effect if running and cursor in start.
      if( state.isRunning && state.isInStart ){
        // checkAndTransistionRecover will change the stage to PAUSED
        // if pointer is in start.
        if(state.stage === RECOVER) {
          checkAndTransistionRecover(state)
        }
        if (state.stage === PAUSED ) {
          const {  pointerLoc } = action.payload
          state.delta = getDelta(state, pointerLoc)
          // Record the history, so make history array.
          // Note this should happen only once at the beginning of the trial
          state.history = []
          record(state)
          // Transistion stage
          state.stage = ACQUIRE
        }
      } // end if state.isRunning && state.isInStart
    }, // end pointerDown
    pointerUp: (state, action) => {
      if( state.isRunning && state.isInStart && state.stage === RETURN ){
        state.stage = PAUSED
      }  // end if state.isRunning && state.isInStart
    },
    /*
     * keyUp is called for escaping from a trial and locating the poriter at Start.
     */
    keyUp: (state, action) => {
      if ( state.stage !== RECOVER ) {
        state.redoCount++
        setRecover(state)
      } else { // state.stage == RECOVER // This is the second keyUp call.
        checkAndTransistionRecover(state)
      } // else // second keyUp call.
    }, // end keyUp
    /*
     *
     */
     setRedoCount: (state, action) =>{
       const redoCount = action.payload.redoCount
       state.redoCount = redoCount
     },
     /*
      *
      */
     setResizeCount: (state, action) =>{
       const resizeCount = action.payload.resizeCount
       state.resizeCount = resizeCount
     },
     /*
      *
      */
      setShowResize: (state, action) =>{
        const showResize = action.payload.showResize
        state.showResize = showResize
      },
    /*
     * Action tick is called by the timer. It sets all the state objects required
     * for drawing everything in the scene: start, cursor and target. The action
     * tick also records the state during the dragging.
     */
    tick: (state, action) => {
      const { time } = action.payload
      // Set state on tick
      setTickState(state, time)
      //Record the history
      if( state.stage === ACQUIRE || state.stage === RETURN  ) record(state)
    }, // end tick
  } // end reducers
})
/*
 * Helper functions
 */
const setRecover = (state) => {
  state.stage = RECOVER
  state.isCursorVisible = false
  state.isPointerVisible = true
}

const checkAndTransistionRecover = (state) => {
  const isPointerInStart = getInBoxCircle(state.pointerLoc, state.start)
  if( isPointerInStart ) {
    // transistion stage
    state.stage = PAUSED
    state.isCursorVisible = true
    state.isPointerVisible = false
    return true
  } // end if isPointerInStart
  else {
    return false
  }
} // checkAndTransistionRecover

const getDelta = (state, payload) => {
  const delta = {
    x: state.cursor.x - payload.x,
    y: state.cursor.y - payload.y
  }
  return delta
}

const setTickState = (state, time) => {
  const {
    // sceneBox,
    start,
    target,
    cursor,
    offsetLoc,
    feedbackCircle
  } = getDrawState(state)
  const isInTarget = getInBoxCircle(cursor, target)
  const isInFeedbackCircle = getInBoxCircle(cursor, feedbackCircle)
  const isInStart = getInBoxCircle(cursor, start)

  state.time = time
  // state.sceneBox = sceneBox
  state.start = start
  state.target = target
  state.cursor = cursor
  state.offsetLoc = offsetLoc
  state.isInTarget = isInTarget
  state.feedbackCircle = feedbackCircle
  state.isInFeedbackCircle = isInFeedbackCircle
  state.isInStart = isInStart
}

const record = state => {
  const {
    time,
    bndRect,
    // sceneBox,
    targetAngle,
    start,
    target,
    cursor,
    feedbackCircle,
    isInTarget,
    isInFeedbackCircle,
    isInStart,
    offsetLoc,
    pointerLoc,
    redoCount,
    resizeCount,
  } = state

  state.history.push({
    time,
    bndRect,
    // sceneBox,
    targetAngle,
    start,
    target,
    cursor,
    feedbackCircle,
    isInTarget,
    isInFeedbackCircle,
    isInStart,
    offsetLoc,
    pointerLoc,
    redoCount,
    resizeCount,
  })
}

/*
 * Exports
 */
export const {
  sceneRun,
  setSceneParams,
  bndRectChange,
  pointerMove,
  pointerDown,
  pointerUp,
  keyUp,
  setRedoCount,
  setResizeCount,
  setShowResize,
  tick,
} = sceneSlice.actions

export const selectBndRect = state => state.root.scene.bndRect
export const selectSceneBox = state => state.root.scene.sceneBox
export const selectStart = state => state.root.scene.start
export const selectCursor = state => state.root.scene.cursor
export const selectTarget = state => state.root.scene.target
export const selectTrialHistory = state => state.root.scene.trialHistory
export const selectIsInTarget = state => state.root.scene.isInTarget
export const selectFeedbackCircle = state => state.root.scene.feedbackCircle
export const selectIsInStart = state => state.root.scene.isInStart
export const selectIsCursorVisible = state => state.root.scene.isCursorVisible
export const selectIsPointerVisible = state => state.root.scene.isPointerVisible
export const selectShowResize = state => state.root.scene.showResize
export const selectResizeModal = state => state.root.scene.resizeModal

export default sceneSlice.reducer
