import{
  nomSceneSize,
  nomMargin,
  nomPadding,
  nomTargetRadius,
} from './geometryConstants'

/*
 * Genral purpose functions
 */
 /*
  * This function calculate the center of the page or window.
  * Need this function because everything except the cursor is drawn relative to page center.
  */
//
export const getPageCenter = (bndRect) => {
  // eslint-disable-next-line
  const {width, height } = bndRect
  const pageCenter = {x: Math.round(width/2), y: Math.round(height/2)}
  return pageCenter
}

/*
 * Conversion to create nominal scene sizes to page or window sizes.
 */
export const getSceneToPage = (bndRect) => {
  // console.log(bndRect)
  const {width, height } = bndRect
  const sceneToPage = Math.min(width, height)/(nomSceneSize + nomMargin)
  return sceneToPage
}

/*
 * Functions for calculating the Scene Box or Frame
 */
export const getSceneSize = bndRect =>
        getSceneToPage(bndRect) * nomSceneSize

export const getSceneBox = (bndRect) => {
  const sceneSize = getSceneSize(bndRect)
  const pageCenter = getPageCenter(bndRect)
  const sceneBox = {
    x: pageCenter.x - Math.round(sceneSize/2),
    y: pageCenter.y - Math.round(sceneSize/2),
    width: sceneSize,
    height: sceneSize
  }
  return sceneBox
}
/*
 * Functions for calculating Target Loctaion (center)
 */
export const degToRadians = Math.PI/180
export const getNomRadius = bndRect =>
      Math.round(nomSceneSize/2) - nomPadding - nomTargetRadius

export const getTargetLoc = (bndRect, angle = 90) => {
  const pageCenter = getPageCenter(bndRect)
  const sceneToPage = getSceneToPage(bndRect)
  const pageRadius = getNomRadius(bndRect) * sceneToPage
  const angleRadians = angle * degToRadians
  return {
    x: pageCenter.x + Math.round(pageRadius * Math.cos(angleRadians)),
    // Note the minus sign below, so degrees progress counter clockwise.
    y: pageCenter.y - Math.round(pageRadius * Math.sin(angleRadians))
  }
}

/*
 * Functions for rotation
 */
 /*
  * Function below from
  * https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
  * See last answer.
  */
 /*
  cx @ Origin X
  cy @ Origin Y
  xy  @ Point X to be rotated
  y  @ Point Y to be rotated
  anticlock_wise @ to rotate point in clockwise direction or anticlockwise , default clockwise
  return @ {x,y}
 */
 export const rotateFloat = (cx, cy, x, y, angle, anticlock_wise = true) => {
     if(angle === 0){
         return {x:parseFloat(x), y:parseFloat(y)}
     }
     let radians
     if(anticlock_wise){
         radians = (Math.PI / 180) * angle;
     }else{
         radians = (Math.PI / -180) * angle;
     }
     const cos = Math.cos(radians);
     const sin = Math.sin(radians);
     const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
     const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
     return {x:nx, y:ny};
  }
/*
 * My function for rotateFloat
  * Wraps the rotateFloat to accept objects and return integers
 */
export const rotate = (center, point, angle) => {
  const cx = center.x
  const cy = center.y
  const {x, y} = point
  const rotatedPoint = rotateFloat(cx, cy, x, y, angle)
  return {x: Math.round(rotatedPoint.x), y: Math.round(rotatedPoint.y)}
}

/*
 * The function getInBoxCricle detremines if a point is a box surrounding a
 * circle.
 * Parameters:
 *    * point = {x, y}
 *    * circle = {x, y, radius}
 * Returns a boolean
 */
export const getInBoxCircle = (point, circle) => {
  const xB = circle.x - circle.radius < point.x && point.x < circle.x + circle.radius
  const yB = circle.y - circle.radius < point.y && point.y < circle.y + circle.radius
  return xB && yB
}

/*
 * This is the old verision of getInTarget.
 * The function getInTarget returns boolean for if the cursor is in the target.
 * Parmeters:
 *   * cursor
 *   * target
 *
 * return boolean
 *
 * Calls helper functions: getCircleXYMinMax and getCoordinateIn
 *
 * Inside is determined by compairsion of min and max of cursor and target
 * in both X and Y coordinate.
 */
 export const getInTarget = (cursor, target) => {
  const t = getCircleXYMinMax(target)
  const c = getCircleXYMinMax(cursor)
  const isInTargetX = getCoordinateIn(c.minX, c.maxX, t.minX, t.maxX)
  const isInTargetY = getCoordinateIn(c.minY, c.maxY, t.minY, t.maxY)
  return isInTargetX && isInTargetY
 }
 const getCoordinateIn = (cursorMin, cursorMax, targetMin, targetMax) => {
   return targetMin < cursorMin && cursorMax < targetMax
 }
 const getCircleXYMinMax = circle =>
 {
   const minX = circle.x - circle.radius
   const maxX = circle.x + circle.radius
   const minY = circle.y - circle.radius
   const maxY = circle.y + circle.radius
   return {minX, maxX, minY, maxY}
 }
