/*
 * Draw States
 * Functions used by sceneRudcer to update the state parmeters for drawing the scene.
 *
 */
 // Helper functions for getDrawState
 import{
   getSceneToPage,
   // getSceneBox,
   getPageCenter,
   getTargetLoc,
   rotate,
 } from './drawUtils'

// Drawing constants
import{
  nomStartRadius,
  nomCursorRadius,
  nomTargetRadius,
  nomFeedbackRadius,
  // rotation,
} from './geometryConstants'

import{
  // PAUSED,
  ACQUIRE,
  RETURN,
  // RECOVER,
} from '../sceneSlice'


/*
 * getDrawState is the main functions for updatinge scene draw states.
 * It is called by sceneRudcer tick action. It returns update draw state parameters.
 * Note the use of "let", these are prameters that are computed differently
 * on the value of stage.
 */
export default function getDrawState( state ) {
  // console.log("In getDrawState")

  const { bndRect, targetAngle } = state
  const sceneToPage = getSceneToPage(bndRect)
  const pageCenter = getPageCenter(bndRect)

  // const sceneBox = getSceneBox(bndRect)
  const start = {...pageCenter, radius: Math.round( sceneToPage * nomStartRadius )}
  const feedbackCircle = {...pageCenter, radius: Math.round( sceneToPage * nomFeedbackRadius)}
  const target = {
                   ...getTargetLoc(bndRect, targetAngle),
                   radius: Math.round( sceneToPage * nomTargetRadius )
                  }
  const pointerLoc = state.pointerLoc
  const stage = state.stage
  const delta = {...state.delta}  // occasionally this is not need, but I don't know a better way.

  // These properties change depending on the value of stae
  let offsetLoc = {...state.offsetLoc}
  let cursor
  if( stage === ACQUIRE || stage === RETURN ) {
    offsetLoc = moveOffsetLoc(pointerLoc, delta)
    const rotation = state.rotation
    cursor = {
               ...moveCursorLoc(start, offsetLoc, rotation),
               // ...moveCursorWithDelta (pointerLoc, delta),
               radius: Math.round( sceneToPage * nomCursorRadius )
             }
   } else {
     cursor = {...pageCenter, radius: Math.round( sceneToPage * nomCursorRadius )}
     offsetLoc = {...pageCenter}
   }
   // Below is the basic "secene" state structre
   return {
     // sceneBox,   // = {x, y, width, height}
     start,      // = {x, y, radius}
     target,     // = {x, y, radius}
     cursor,     // = {x , y, radius}
     offsetLoc,  // = {x, y} or undefined
     feedbackCircle,   // = {x, y, radius}
   }
} // end getDrawState

/*
 * Helper functions for getDrawState
 */
/*
 * moveOffsetLoc function displaces offsetLoc after pointerDown by "delta".
 */
const moveOffsetLoc = (pointerLoc, delta) => {
     // console.log(delta.x)
 const offsetLoc = {
   x: pointerLoc.x + delta.x,
   y: pointerLoc.y + delta.y
 }
 return offsetLoc
}
/*
 * moveCursor function move the cursor by rotating offsetLoc about the "start".
 */
const moveCursorLoc = (start, offsetLoc, rotateAngle) => {
 const startLoc = {x: start.x, y: start.y}
 const cursorLoc = rotate(startLoc, offsetLoc, rotateAngle)
 return cursorLoc
}
/*
 * moveCursorWithDelta function was only used during development.
 */
// eslint-disable-next-line
const moveCursorWithDelta = (pointerLoc, delta) => {
 const cursorLoc = {
   x: pointerLoc.x + delta.x,
   y: pointerLoc.y + delta.y
 }
 return cursorLoc
}
